import React from 'react';
import {
    HashRouter as Router,
    Route,
    Routes,
    } from 'react-router-dom';
// import { BrowserRouter as Router,Routes, Route } from 'react-router-dom'; 

import Track from './components/Store';
import Album from './components/Store';

import './App.css';


function App() {

    return (  
        <Router>
            <div className="App">  
            <Routes>  
                <Route exact path='/' element={<Track />} />
                <Route exact path='/tie/share/track/' element={<Track />} />
                <Route exact path='/tie/share/album/' element={<Album />} />
            </Routes>  
            </div> 
        </Router>  
    );
}

export default App;
